<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-card outlined class="mt-2">
        <v-toolbar flat color="grey lighten-3" height="auto">
          <v-list-item class="pl-0 pr-0" three-line>
            <v-row no-gutters>
              <v-col cols="8">
                <v-list-item-content>
                  <v-list-item-title class="title text-wrap">
                    {{ tour.name }}: {{ itinerary.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <router-link
                      :to="{
                        name: 'module-drum-customers-profile',
                        params: { customerId: tour.lead_customer.id },
                      }"
                      >{{ tour.lead_customer.full_name }}</router-link
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ tour.lead_customer.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
              <v-col cols="4" class="text-right pt-3">
                <v-row justify="end" no-gutters>
                  <v-col cols="12">
                    <v-btn
                      dark
                      small
                      depressed
                      :loading="pdfLoading"
                      @click="downloadPdf"
                    >
                      <v-icon left>mdi-file-pdf-box</v-icon>Download as PDF
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      dark
                      small
                      class="mt-2"
                      depressed
                      :href="getQuoteUrl(itinerary.unique_id)"
                      target="_blank"
                    >
                      <v-icon left>mdi-monitor-dashboard</v-icon>See Customer's
                      View
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-list-item>
          <template v-slot:extension>
            <v-tabs dark background-color="grey darken-2">
              <v-tab :to="{ name: `module-drum-${linkName}-itinerary` }"
                >Overview</v-tab
              >
              <v-tab
                :to="{ name: `module-drum-${linkName}-itinerary-mainDetails` }"
                >Main Details</v-tab
              >
              <v-tab
                :to="{ name: `module-drum-${linkName}-itinerary-itinerary` }"
                >Itinerary</v-tab
              >
              <v-tab :to="{ name: `module-drum-${linkName}-itinerary-images` }"
                >Images</v-tab
              >
              <v-tab :to="{ name: `module-drum-${linkName}-itinerary-pricing` }"
                >Pricing</v-tab
              >
              <v-tab :to="{ name: `module-drum-${linkName}-itinerary-costs` }"
                >Costs</v-tab
              >
              <v-tab
                :to="{ name: `module-drum-${linkName}-itinerary-payments` }"
                >Payments</v-tab
              >
              <v-tab :to="{ name: `module-drum-${linkName}-itinerary-golfers` }"
                >Golfers</v-tab
              >
              <v-tab
                :to="{ name: `module-drum-${linkName}-itinerary-invoices` }"
                >Invoices</v-tab
              >
              <v-tab :to="{ name: `module-drum-${linkName}-itinerary-deposit` }"
                >Deposit</v-tab
              >
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-5">
          <router-view
            v-bind:tour="tour"
            v-bind:itinerary="itinerary"
          ></router-view>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [],
      pdfLoading: false,
    };
  },

  computed: {
    tour() {
      return this.$store.state.drum.tours["tour"];
    },
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },
    linkName() {
      return this.tour.status === "tour" ? "tours" : "proposals";
    },
  },

  watch: {
    tour() {
      this.setBreadcrumbs();
    },
  },

  created() {
    this.setBreadcrumbs();
  },

  methods: {
    getQuoteUrl(unique_id) {
      return process.env.VUE_APP_DRUMGOLF_URL + unique_id;
    },

    setBreadcrumbs() {
      this.breadcrumbs = [];

      if (this.tour.status === "tour") {
        this.breadcrumbs.push({
          text: "Tours",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-tours",
            params: { id: this.$route.params.id },
          },
        });
      } else {
        this.breadcrumbs.push({
          text: "Proposals",
          disabled: false,
          exact: true,
          to: {
            name: "module-drum-proposals",
            params: { id: this.$route.params.id },
          },
        });
      }

      this.breadcrumbs.push(
        {
          text: this.tour.name,
          disabled: false,
          exact: true,
          to: {
            name:
              this.tour.status === "tour"
                ? "module-drum-tours-individual"
                : "module-drum-proposals-individual",
            params: {
              id: this.$route.params.id,
              tourId: this.$route.params.tourId,
            },
          },
        },
        {
          text: "Itinerary",
          disabled: true,
        }
      );
    },
    downloadPdf() {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.pdfLoading = true;

      this.$store
        .dispatch("drum/tours/downloadItineraryPdf", {
          appId,
          tourId,
          itineraryId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "itinerary.pdf";
          link.click();
          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },
  },
};
</script>
